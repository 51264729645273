/* eslint-disable no-console */
/* eslint-disable no-alert */
import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as styles from './contact-widget.module.scss';
import SocialIcons from './social-icons';

export const functionsUrlRoot = 'https://crunch.josiahbryan.com/uxg/contact';
// process.env.NODE_ENV === 'production'
// 	? 'https://crunch.josiahbryan.com/uxg/contact'
// 	: 'http://localhost:8123/uxg/contact';

export default class ContactWidget extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			title: '',
			name: '',
			company: '',
			email: '',
			phone: '',
			message: '',
		};

		this.changed = this.inputValueChanged.bind(this);
		this.submit = this.sendContactForm.bind(this);

		// console.log(`this.functionsUrlRoot: ${this.functionsUrlRoot}, process.env.NODE_ENV: ${process.env.NODE_ENV}`);
	}

	inputValueChanged(event) {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;

		this.setState({
			[name]: value,
		});
	}

	submitToServer() {
		return new Promise((resolve, reject) => {
			fetch(`${functionsUrlRoot}/general`, {
				method: 'POST',
				body: JSON.stringify(this.state),
			})
				.then((response) => {
					resolve(response);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	sendContactForm() {
		console.log('[sendContactForm] this.state=', this.state);
		this.submitToServer().then((response) => {
			// const body = response.json();
			if (Number(response.status) !== 200) {
				console.error('Error response from server:', response);
				window.alert('Error submitting the form.');
			} else {
				console.log('Success response from server:', response);
				window.alert('Thanks, form was submitted successfully!');
			}
		});
	}

	render() {
		return (
			<div className={styles.contactWidget}>
				<h1>Contact Us</h1>

				{this.props.showSocial && <SocialIcons className={styles.socialRow} />}

				<div className={`container-fluid ${styles.contactItemWrap}`}>
					<div className="row">
						<div className="col-sm-4">
							<div className={styles.contactItem}>
								<FontAwesomeIcon icon="phone" size="2x" />
								<p>+1 (800) 213-9050 </p>
							</div>
						</div>
						<div className="col-sm-4">
							<div className={styles.contactItem}>
								<FontAwesomeIcon icon="map-pin" size="2x" />
								<p>
									The UXology Group
									<br />
									4629 Cass St
									<br />
									Suite #269
									<br />
									San Diego, CA 92109
								</p>
							</div>
						</div>
						<div className="col-sm-4">
							<div className={styles.contactItem}>
								<FontAwesomeIcon icon="envelope" size="2x" />
								<p>
									<a href="mailto:sales@uxologygroup.com">
										sales@uxologygroup.com
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>

				<Form className={styles.contactForm}>
					<FormGroup className={styles.formGroup}>
						<Label for="name">Name</Label>
						<Input
							type="text"
							autoComplete="name"
							name="name"
							id="name"
							placeholder=""
							value={this.state.name}
							onChange={this.changed}
						/>
					</FormGroup>
					<FormGroup className={styles.formGroup}>
						<Label for="title">Title</Label>
						<Input
							type="text"
							autoComplete="title"
							name="title"
							id="title"
							placeholder=""
							value={this.state.title}
							onChange={this.changed}
						/>
					</FormGroup>
					<FormGroup className={styles.formGroup}>
						<Label for="company">Company</Label>
						<Input
							type="text"
							autoComplete="organization"
							name="company"
							id="company"
							placeholder=""
							value={this.state.company}
							onChange={this.changed}
						/>
					</FormGroup>
					<FormGroup className={styles.formGroup}>
						<Label for="name">Email</Label>
						<Input
							type="text"
							autoComplete="email"
							name="email"
							id="email"
							placeholder=""
							value={this.state.email}
							onChange={this.changed}
						/>
					</FormGroup>
					<FormGroup className={styles.formGroup}>
						<Label for="phone">Phone Number</Label>
						<Input
							type="text"
							autoComplete="tel"
							name="phone"
							id="phone"
							placeholder=""
							value={this.state.phone}
							onChange={this.changed}
						/>
					</FormGroup>
					<FormGroup className={styles.messageGroup}>
						<Label for="message">Message</Label>
						<Input
							type="textarea"
							autoComplete="no"
							name="message"
							id="message"
							placeholder=""
							value={this.state.message}
							onChange={this.changed}
						/>
					</FormGroup>

					<div className={styles.btnWrap}>
						<Button onClick={this.submit}>Send</Button>
					</div>
				</Form>
			</div>
		);
	}
}
