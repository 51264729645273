import React from 'react';
import { Link } from 'gatsby';
import SocialIcons from './social-icons';
import * as styles from './footer.module.scss';
import { PageColors, PageFooterColors } from './layout';

import uxgSlugBlue from '../images/uxg-slug.png';
import uxgSlugGreen from '../images/uxg-slug-green.png';
import uxgLogoPyramid from '../images/uxg-logo-pyramid.png';
import uxologyGroupLogo from '../images/uxg_with_white_bg.png';

const checkPage = (page, props) =>
	props.page === page ||
	(props.page === 'contact' && props.location?.state?.page === page);

export default function Footer(props) {
	const prevPage = props.location?.state?.page;
	const isContactPageOpenedDirectly = !prevPage && props.page === 'contact';
	const imgProps = {
		className:
			checkPage('experience', props) || checkPage('about', props)
				? styles.blueBorder
				: '',
		src:
			checkPage('index', props) ||
			checkPage('coaching', props) ||
			isContactPageOpenedDirectly
				? uxgSlugBlue
				: uxgSlugGreen,
	};
	const mapBorder = (
		<>
			{(checkPage('index', props) || isContactPageOpenedDirectly) && (
				<div className={styles.overviewUxgLogoRepeat} />
			)}
			{checkPage('services', props) && (
				<div className={styles.servicesUxgLogoRepeat} />
			)}
			{checkPage('experience', props) && (
				<div className={styles.uxgLogoPyramid}>
					<img src={uxgLogoPyramid} alt="UXG logo pyramid" />
					<img src={uxgLogoPyramid} alt="UXG logo pyramid" />
				</div>
			)}
			{checkPage('about', props) && <div className={styles.uxgLogoBorder} />}
		</>
	);

	return (
		<>
			<a name="map"></a>
			<div className={styles.footer}>
				{props.page === 'contact' ? (
					<div className={styles.mapFrame}>
						<iframe
							width="100%"
							height="450"
							frameBorder="0"
							title="Google map"
							src="https://www.google.com/maps/embed/v1/place?zoom=6&q=The%20UXology%20Group%204629%20Cass%20St%20Suite%20%23269%20San%20Diego%2C%20CA%2092109&key=AIzaSyDRhh6Z1eOvfGeJyXfXJn1pwGPtt-ZTTv8"
							allowFullScreen
						/>
						{mapBorder}
					</div>
				) : (
					<div
						className={`${styles.mapLink} ${
							(checkPage('index', props) || checkPage('services', props)) &&
							styles.extraPadding
						}`}
					>
						<Link to="/contact/#map" state={{ page: props.page }}>
							Locate us on the map
						</Link>
						{mapBorder}
					</div>
				)}
				<div
					className={`${styles.footerColorWrap} ${
						props.page === 'index' ? styles.topBorder : ''
					}`}
					style={{
						background: PageColors[prevPage ?? props.page],
						color: PageFooterColors[prevPage ?? props.page],
					}}
				>
					<SocialIcons className={styles.social} />
					{checkPage('coaching', props) ? (
						<div className={styles.coachingFooter}>
							<img src={uxologyGroupLogo} alt="UXG slug" />
							<span className={styles.copyright}>
								&copy; 2018 UXOLOGY GROUP INTERNATIONAL
							</span>
							<div className={styles.uxgLogoRepeat} />
						</div>
					) : (
						<div
							className={`${styles.copy} ${
								checkPage('experience', props) ? styles.extraMargin : ''
							}`}
						>
							<img {...imgProps} alt="UXG slug" />
							<span className={styles.copyright}>
								&copy; 2018 UXOLOGY GROUP INTERNATIONAL
							</span>
							<img {...imgProps} alt="UXG slug" />
						</div>
					)}
				</div>
			</div>
		</>
	);
}
