// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--3L5Rb";
export var copyright = "footer-module--copyright--3WaIr";
export var mapLink = "footer-module--mapLink--kUycM";
export var extraPadding = "footer-module--extraPadding--1pyQz";
export var overviewUxgLogoRepeat = "footer-module--overviewUxgLogoRepeat--3eldh";
export var servicesUxgLogoRepeat = "footer-module--servicesUxgLogoRepeat--1TXAB";
export var uxgLogoPyramid = "footer-module--uxgLogoPyramid--b5NtV";
export var uxgLogoBorder = "footer-module--uxgLogoBorder--Akvhp";
export var mapFrame = "footer-module--mapFrame--26qaE";
export var topBorder = "footer-module--topBorder--shEnZ";
export var footerColorWrap = "footer-module--footerColorWrap--1i9sl";
export var social = "footer-module--social--2l7-4";
export var copy = "footer-module--copy--1XmB4";
export var extraMargin = "footer-module--extraMargin--bvhnp";
export var blueBorder = "footer-module--blueBorder--31j2o";
export var coachingFooter = "footer-module--coachingFooter--1_AXP";
export var uxgLogoRepeat = "footer-module--uxgLogoRepeat--3G4sJ";